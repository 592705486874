import React from "react";
import { textProcess } from "@/lib/utils/textprocess";
import ShowSingleHome from "./show-single-home";

export default function ShowArrayHome({
  itemdata,
  itemmeta,
  placeholderdata = {
    show_name: "Loading...",
    show_description: "Loading...",
    show_id: 0,
    weekday: "0",
    frequency: "daily",
    start_time: "00:00:00",
    end_time: "00:00:00",
  },
  isLoading = false,
  isError = false,
  nodata,
}) {
  if (isError) {
    return <div className="px-4">Something went wrong...</div>;
  }

  return (
    <>
      <ul
        className={` divide-y w-full -mt-2
         divide-gray-700 border-gray-700
      `}
      >
        {itemdata?.length == 0 && (
          <li className="text-center my-10 text-xl">{textProcess(nodata)}</li>
        )}

        {itemdata?.map((e, index) => (
          <div className="" key={e?.show_id}>
            <ShowSingleHome display_data={isLoading ? placeholderdata : e} />
          </div>
        ))}
      </ul>
    </>
  );
}
