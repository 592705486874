import Link from "next/link";
import SvgJsx from "./svg/svg-jsx";

export function RssLink({ show_rss, rss_link, rss_text }) {
  return (
    <>
      {show_rss && (
        <div className="flex-0 flex ml-1">
          <a
            target={`_blank`}
            href={rss_link}
            className="h-10 flex items-center content-center px-2 py-1 bg-brand hover:bg-brand-darkest text-white cursor-pointer rounded-md"
          >
            <div className="flex-0 ">
              <SvgJsx
                type={"fill"}
                icon={"rss-sm"}
                className={`h-4 w-4`}
                title={rss_text}
              />
            </div>
            <div className="block font-bold text-sm lg:text-lg ml-1 ">
              {rss_text}
            </div>
          </a>
        </div>
      )}
    </>
  );
}
