import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { getScheduleStrTimeItem } from "@/lib/utils/getScheduleStr";
import { getTimeStrFromMins } from "@/lib/utils/getTimeFromMins";
import { getWeekday } from "@/lib/utils/getWeekday";
import { hasData } from "@/lib/utils/hasData";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { linkifyUsernameId } from "@/lib/utils/linkify";
import { textProcess } from "@/lib/utils/textprocess";
import moment from "moment-timezone";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";

function ShowSingleHome({ display_data }) {
  const [all_folks, set_all_folks] = useState(
    display_data?.bol_hosts_json?.toString() == "1" &&
      IsJsonValid(display_data?.hosts_json)
      ? JSON.parse(display_data?.hosts_json)?.filter((e) => e?.type !== "crew")
      : []
  );
  useEffect(() => {
    if (
      display_data?.bol_hosts_json?.toString() == "1" &&
      IsJsonValid(display_data?.hosts_json)
    ) {
      set_all_folks(
        JSON.parse(display_data?.hosts_json)?.filter((e) => e?.type !== "crew")
      );
    }
  }, [display_data?.bol_hosts_json]);

  return (
    <div
      className="flex-1 w-full flex items-center content-center group
          "
    >
      <div
        className="flex-1 
          "
      >
        <div className=" block  my-2 ">
          <div className="flex-0 mr-2 text-light w-full">
            <div className="flex  items-center content-center text-sm">
              <div className="font-bold mr-2">
                {textProcess(getWeekday(display_data?.weekday)?.long)}
              </div>
              <div className="font-thin text-xs">
                {getTimeStrFromMins(display_data?.start_total, "regular")}
                {"-"}
                {getTimeStrFromMins(display_data?.end_total, "regular")}{" "}
                {hasData(display_data?.timezone)
                  ? moment.tz(display_data?.timezone).zoneAbbr()
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex-1  ml-0">
            <div className="flex items-center content-center  ">
              <div className="flex-1">
                <div className=" text-white ">
                  <div className="">
                    <span className="font-bold">
                      {textProcess(display_data?.show_name)}
                    </span>
                  </div>
                  <div className="italic text-sm">
                    <span>
                      {textProcess(`featuring`)}{" "}
                      {linkifyUsernameId(
                        display_data?.user_name,
                        display_data?.id
                      )}
                    </span>
                    {all_folks.map((e, index) => (
                      <Fragment key={index}>
                        {index < all_folks.length - 1 && (
                          <span className="">
                            , {linkifyUsernameId(e?.name, e?.id)}
                          </span>
                        )}

                        {index == all_folks.length - 1 && (
                          <span className="ml-1">
                            &amp; {linkifyUsernameId(e?.name, e?.id)}
                          </span>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowSingleHome;
