import SvgJsx from "../templateux/svg/svg-jsx";
import ProfileSection from "./profile-section";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { textProcess } from "@/lib/utils/textprocess";
import SubSection from "./subsection";
import { useCallback, useContext, useEffect, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import { TribeContext } from "../context/tribe-context";
import { hasData } from "@/lib/utils/hasData";
import { signIn, useSession } from "next-auth/client";

export default function ProfileRSS({
  profiledata,
  ssr_isadmin,
  set_isOpen,
  userdata,
}) {
  const ssr_data_tribe = useContext(TribeContext);
  const [session, isLoading] = useSession();

  return (
    <>
      <div className="w-full">
        {profiledata?.bol_podcast_rss?.toString() == "1" && (
          <ProfileSection>
            <SubSection
              action_url={`/${profiledata?.user_name}/settings/content?panel=podcast#podcast`}
              action_text={`edit`}
              action_condition={ssr_isadmin?.[`profile`]}
              headertitle={"Media Links"}
            >
              <div className={`text-sm`}>
                <ul className=" mt-2 flex flex-col gap-y-1">
                  <RssItem
                    title={"Podcast & RSS feeds"}
                    icon={"rss-sm"}
                    onClick={async () => {
                      if (!session) {
                        await signIn();
                      } else {
                        set_isOpen("rss");
                      }
                    }}
                  />
                  <RssItem
                    title={"Embeddable Player"}
                    icon={"code-sm"}
                    onClick={async () => {
                      if (!session) {
                        await signIn();
                      } else {
                        set_isOpen("embed");
                      }
                    }}
                  />
                </ul>
              </div>
            </SubSection>
          </ProfileSection>
        )}
      </div>
    </>
  );
}

export const RssItem = ({ title, icon, onClick }) => {
  return (
    <>
      <li className="list-none flex items-center content-center">
        <div
          onClick={() => onClick()}
          className={`cursor-pointer flex-1 w-full max-w-sm flex flex-0 items-center content-center  rounded-md
                                   text-blue-400 hover:text-blue-100 hover:border-gray-700`}
        >
          <SvgJsx
            type={"fill"}
            icon={icon}
            className={`h-4 w-4 mr-2`}
            title={textProcess(title)}
          />
          <span>{textProcess(title)}</span>
        </div>
      </li>
    </>
  );
};

export function ProfileRssModal({ userdata, rss_array }) {
  const [session, isLoading] = useSession();

  const [dynamic_key, set_dynamic_key] = useState(null);

  useEffect(() => {
    if (userdata && userdata?.id > 0) {
      onHandleKey();
    }
  }, [userdata]);

  const onHandleKey = async () => {
    var podcast_api_key = await keyHandler();
    await set_dynamic_key(podcast_api_key);
  };

  const keyHandler = async () => {
    if (!session) signIn();
    if (session && hasData(userdata?.id)) {
      const res = await fetch(`/api/private/user/select-podcast-api-key`);
      const podcast_api_key = await res.json();
      return podcast_api_key;
    }
  };

  if (isLoading || !userdata || !hasData(dynamic_key))
    return <div className="my-5 text-center px-2">Loading...</div>;

  return (
    <>
      <div className="w-full">
        <div className="w-full text-center font-bold mt-5">
          Add these URLs to your favorite podcast players or feed readers.
        </div>
        <div className="text-center mb-10">
          Remember these URLs are unique to your account. Do not share them!
        </div>

        {rss_array.map((r, index) => {
          return (
            <ProfileRssModalItem
              key={index}
              title={r.title}
              url={r.url.replace("[dynamic_key]", dynamic_key)}
              dynamic_key={dynamic_key}
              keyHandler={keyHandler}
            />
          );
        })}
      </div>
    </>
  );
}

export function ProfileRssModalItem({ title, url, dynamic_key, keyHandler }) {
  const [isMounted, set_isMounted] = useState(false);
  const [show_copied, set_show_copied] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const copyFade = () => {
    set_show_copied(true);
    setTimeout(() => {
      set_show_copied(false);
    }, 2000);
  };

  const triggerToast = useCallback((msg) => {
    toastConfig({
      time: 5000,
      className: "",
      position: "right",
    });
    toast(msg, {
      time: 3000,
      className: "",
      clickable: true,
      clickClosable: false,
    });
  }, []);

  return (
    <>
      <div className="text-xl px-4 mt-4 font-bold">{title}</div>
      {!hasData(dynamic_key) && (
        <div className=" mt-2 mb-5 px-4 flex">
          <div
            className="underline hover:no-underline text-blue-500 cursor-pointer"
            onClick={() => keyHandler()}
          >
            Retrieve link
          </div>
        </div>
      )}
      {hasData(dynamic_key) && (
        <CopyToClipboard
          text={url}
          onCopy={() => {
            triggerToast(`Copied to Clipboard`);
            copyFade();
          }}
        >
          <div className="w-full text-center mt-2 mb-5 px-4 flex items-center content-center">
            <div className="flex-1">
              <input
                className={`w-full cursor-pointer ${
                  show_copied
                    ? `bg-blue-600 text-white `
                    : `bg-gray-900 text-white`
                }`}
                value={url}
                readOnly
              />
            </div>
            <div>
              <SvgJsx
                type={"outline"}
                icon={show_copied ? "check" : "clipboard"}
                className={`h-6 w-6 ml-2 cursor-pointer`}
                title={show_copied ? "Copied!" : "Copy to Clipboard"}
              />
            </div>
          </div>
        </CopyToClipboard>
      )}
    </>
  );
}
