import { chatauthor } from "@/components/chat/chatauthor";
import { config } from "@/lib/config";
import ChatFlair, { ChatFlairUser } from "@/components/chat/chat-flair";
import { validJson } from "@/lib/utils/isJsonValid";
import { hasData } from "@/lib/utils/hasData";

import AvatarFlairSizes from "./avatar-flair-sizes";
import { Decorate } from "@/lib/utils/decorate";
import { srcSafe } from "@/lib/utils/srcSafe";

function AuthorCredit({
  display_data,
  isPlaying = false,
  profiledata = {
    bol_flair: 0,
    flair_json: [],
    flair_tag_json: [],
    id: 0,
    user_name: "",
    bol_staff_help: 0,
    staff_json: [],
    bol_admin_help: 0,
    contributor_level: 0,
    bot_default_name: config.chat.bot.default_name,
    bot_default_image: config.chat.bot.default_image,
  },
  show_avatar = true,
  show_user_name = true,
  show_flair = true,
  custom_flair = "",
  flair_t = -1,
  flair_b = config.chat.flair.defaultcolorbg,
  flair_c = config.chat.flair.defaultcolortext,
  bol_mod = 0,
  //chatWidth = config.chat.windowoffset.minmessagewidth,
  isAvatarHiddenOnMobile = false,
  size = "tiny",
}) {
  return (
    <>
      <div className="flex-0 flex items-center content-center   h-8">
        {/* AVATAR + NAME CONTAINER */}
        <div
          className={`flex-0 flex flex-none items-center content-center group`}
        >
          {/* AVATAR CONTAINER */}
          {show_avatar && (
            <div className={`flex-0 flex items-center content-center`}>
              {/* AVATAR FLAIR */}

              <div
                className={`block ${
                  isAvatarHiddenOnMobile ? "xs:hidden" : ""
                }   mr-2.5`}
              >
                <AvatarFlairSizes
                  isPlaying={isPlaying}
                  isContributor={display_data?.contributor_level > 0}
                  isBot={
                    ["server", "bot"].indexOf(display_data?.type?.toString()) >
                    -1
                  }
                  avatar_url={srcSafe(
                    chatauthor(
                      display_data,
                      `avatar_url`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )
                  )}
                  avatar_url_sfw={srcSafe(
                    chatauthor(
                      display_data,
                      `avatar_url_sfw`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )
                  )}
                  bol_nsfw={chatauthor(
                    display_data,
                    `bol_nsfw`,
                    profiledata?.bot_default_name,
                    profiledata?.bot_default_image
                  )}
                  avatar_crdate={chatauthor(
                    display_data,
                    `avatar_crdate`,
                    profiledata?.bot_default_name,
                    profiledata?.bot_default_image
                  )}
                  id={chatauthor(
                    display_data,
                    `id`,
                    profiledata?.bot_default_name,
                    profiledata?.bot_default_image
                  )}
                  user_name={chatauthor(
                    display_data,
                    `user_name`,
                    profiledata?.bot_default_name,
                    profiledata?.bot_default_image
                  )}
                  size={size}
                />
              </div>
            </div>
          )}

          {/* USERNAME - ALL CONTAINER */}
          {show_user_name && (
            <div className="flex items-center content-center">
              {/* USERNAME - TOP */}
              <div className="flex items-center content-center">
                {/* USERNAME */}
                <div
                  className={`flex-0 font-bold  mr-1 text-sm xs:text-base"
                  }`}
                >
                  <Decorate
                    text={
                      chatauthor(
                        display_data,
                        `user_name`,
                        profiledata?.bot_default_name,
                        profiledata?.bot_default_image
                      ) || `[hidden]`
                    }
                    isDecorate={display_data?.contributor_level > 0}
                    isReminder={false}
                  />
                </div>

                {/* CONTRIBUTOR */}
                {/* {display_data?.contributor_level > 0 && (
                  <div className="text-xl font-bold">
                    <ProfileContributorTag showReminder={false} size={12} />
                  </div>
                )} */}
              </div>

              {/* USERNAME - BOTTOM (FLAIRS) */}
              <div className="flex flex-0">
                <div className="flex items-center content-center">
                  {/* HOST FLARE */}
                  {show_flair && (
                    <>
                      {display_data?.user_name?.toLowerCase() ==
                        profiledata?.user_name?.toLowerCase() && (
                        <div className="flex-0 mr-1 mb-1">
                          <ChatFlair
                            bgcolor="bg-green-600"
                            text={"host"}
                            icon="shield-check-sm"
                          />
                        </div>
                      )}

                      {/* MOD FLARE */}
                      {bol_mod?.toString() == "1" && (
                        <div className="flex-0 mr-1 mb-1">
                          <ChatFlair
                            bgcolor="bg-green-600"
                            text={"mod"}
                            icon="shield-check-sm"
                          />
                        </div>
                      )}

                      {/* BOT FLARE */}
                      {["server", "bot"].indexOf(
                        display_data?.type?.toString()
                      ) > -1 && (
                        <div className="flex-0 mr-1 mb-1">
                          <ChatFlair
                            bgcolor="bg-gray-500"
                            text={"bot"}
                            icon="shield-check-sm"
                          />
                        </div>
                      )}

                      {/* CUSTOM FLARE */}
                      {profiledata?.bol_flair?.toString() == "1" &&
                        custom_flair?.length > 0 && (
                          <div className="flex-0 mr-1 mb-1">
                            <ChatFlairUser
                              json={{
                                tag: custom_flair,
                                t: flair_t,
                                b: flair_b,
                                c: flair_c,
                              }}
                              flair_array={
                                hasData(profiledata?.flair_tag_json)
                                  ? validJson(
                                      profiledata?.flair_tag_json?.toString()
                                    )
                                  : []
                              }
                            />
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AuthorCredit;
