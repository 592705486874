import { config } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";

export const getFlairTag = (x, x_flair_array) => {
  if (!hasData(x?.t) || x?.t == -1)
    return {
      t: x?.tag?.substring(0, config.chat.flair.maxtaglength),
      b: x?.b || config.chat.flair.defaultcolorbg,
      c: x?.c || config.chat.flair.defaultcolortext,
    };
  return {
    t: x_flair_array[parseInt(x?.t)]?.t,
    b: x_flair_array[parseInt(x?.t)]?.b || config.chat.flair.defaultcolorbg,
    c: x_flair_array[parseInt(x?.t)]?.c || config.chat.flair.defaultcolortext,
  };
};
