import Link from "next/link";
import HeaderRow from "./headerrow";
import { textProcess } from "@/lib/utils/textprocess";

export default function SubSection({
  headertitle,
  action_condition,
  action_url,
  action_text,
  action_extra = <></>,
  htype = "h2",
  children,
}) {
  return (
    <>
      <HeaderRow
        headertitle={textProcess(headertitle)}
        action_condition={action_condition}
        action_align="right"
        htype={htype}
        action={
          <>
            <div className="flex-0 ">
              <Link
                href={action_url}
                className={`flex items-center content-center border px-1.5 py-0.5 rounded-md hover:shadow-sm 
                                            text-gray-500 border-gray-500 hover:border-white hover:text-white
                                                `}
              >
                <span className="flex-0 text-2xs font-bold">{action_text}</span>
              </Link>
            </div>
            {action_extra}
          </>
        }
      />
      <div className="p-2 border border-gray-800 rounded-b-md">{children}</div>
    </>
  );
}
