import Link from "next/link";
import SvgJsx from "../templateux/svg/svg-jsx";

export default function HeaderRow({
  headertitle,
  action_condition,
  action,
  action_align = "left",
  htype = "h2",
}) {
  return (
    <>
      <div className="flex items-start content-center w-full bg-gray-800  pl-[8px] pr-[4px] rounded-t-md">
        <div className={`${action_align == "left" ? "flex-0" : "flex-1"} `}>
          {
            {
              h1: (
                <h1 className={`font-bold text-xl pb-1 text-white mt-1 `}>
                  {headertitle}
                </h1>
              ),
              h2: (
                <h2 className={`font-bold text-lg  text-white mt-1 `}>
                  {headertitle}
                </h2>
              ),
            }[htype]
          }
        </div>
        {action_condition && (
          <div className="flex-0 flex items-center content-center ml-1 mt-1.5">
            {action}
          </div>
        )}
      </div>
    </>
  );
}
