import { getScheduleStrTimeItem } from "./getScheduleStr";

export const getTimeStrFromMins = (mins, type = "army") => {
  var meridian = "am";
  var hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  if (type == "army") return `${formattedHours}:${formattedMinutes}`;

  if (hours > 12) {
    hours = hours % 12;
    meridian = "pm";
  }

  if (hours == 0) {
    hours = 12;
  }

  return getScheduleStrTimeItem(hours, minutes, meridian);
};

export const getMinsFromTime = (hours, minutes, meridian) => {
  //convert "01" -> 1
  var vhours = parseInt(hours);
  var vminutes = parseInt(minutes);

  //double up PM
  if (meridian?.toLowerCase() == "pm") vhours = vhours + 12;

  //normalize 12am as 0
  if (vhours == 12 && meridian?.toLowerCase() == "am") vhours = 0;

  return vhours * 60 + vminutes;
};
