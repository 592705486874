import moment from "moment-timezone";
import { getWeekday } from "./getWeekday";

export const getScheduleStr = (
  weekday,
  start_hours,
  start_minutes,
  start_meridian,
  end_hours,
  end_minutes,
  end_meridian,
  timezone
) => {
  return `${getWeekday(weekday).long} ${getScheduleStrTimeItem(
    start_hours,
    start_minutes,
    start_meridian
  )} - ${getScheduleStrTimeItem(end_hours, end_minutes, end_meridian)} ${moment
    .tz(timezone)
    .zoneAbbr()}`;
};

export const getScheduleStrTimeItem = (hours, minutes, meridian) => {
  return `${hours}${parseInt(minutes) > 0 ? `:${minutes}` : ``}${meridian}`;
};
