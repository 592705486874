import { hasData } from "./hasData";

export const getWeekday = (n) => {
  if (!hasData(n)) return { long: null, short: null };

  if (parseInt(n) === 0) return { long: "Monday", short: "Mon" };
  if (parseInt(n) === 1) return { long: "Tuesday", short: "Tue" };
  if (parseInt(n) === 2) return { long: "Wednesday", short: "Wed" };
  if (parseInt(n) === 3) return { long: "Thursday", short: "Thu" };
  if (parseInt(n) === 4) return { long: "Friday", short: "Fri" };
  if (parseInt(n) === 5) return { long: "Saturday", short: "Sat" };
  if (parseInt(n) === 6) return { long: "Sunday", short: "Sun" };
  return { long: null, short: null };
};
