import { textProcess } from "@/lib/utils/textprocess";
import { getFlairTag } from "../templateux/sortable-form/users-flair-modal/getFlairTag";
import { useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import SvgJsx from "../templateux/svg/svg-jsx";

export default function ChatFlair({
  text,
  bgcolor,
  textcolor = "text-white",
  style = null,
  icon = "star-sm",
}) {
  return (
    <>
      <div className="flex-0 mr-0.5 mt-1">
        <div
          style={style}
          className={`flex items-center content-center h-[12px]
                        
                            ${bgcolor} ${textcolor}  px-1 py-0.5 rounded-sm text-3xs font-base `}
        >
          {hasData(icon) && (
            <SvgJsx
              type={`fill`}
              icon={icon}
              className={` w-2 h-2 flex-0 mr-0.5`}
              title={text}
            />
          )}
          <div title={text} className={`flex-1`}>
            {textProcess(text)}
          </div>
        </div>
      </div>
    </>
  );
}

export function ChatFlairUser({ json, flair_array }) {
  const [flair_state, set_flair_state] = useState(
    getFlairTag(json, flair_array)
  );

  useEffect(() => {
    if (hasData(json?.tag)) set_flair_state(getFlairTag(json, flair_array));
  }, [json, flair_array]);

  return (
    <>
      <ChatFlair
        text={flair_state?.t}
        bgcolor={null}
        textcolor={null}
        icon={null}
        style={{
          backgroundColor: getFlairTag(json, flair_array)?.b,
          color: getFlairTag(json, flair_array)?.c,
        }}
      />
    </>
  );
}
