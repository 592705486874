import { config } from "@/lib/config";

export const chatObject = (
  name,
  image,
  default_image = config.chat.bot.default_image
) => {
  return {
    id: 0,
    chatter_id: 0,
    user_name: name,
    avatar_url: image,
    bol_nsfw: 0,
    avatar_url_sfw: default_image,
    avatar_crdate: ``,
    custom_flair: ``,
    flair_t: -1,
    flair_b: config.chat.flair.defaultcolorbg,
    flair_c: config.chat.flair.defaultcolortext,
    bol_mod: 0,
  };
};

export function chatauthor(
  chat,
  field,
  bot_name = config.chat.bot.default_name,
  bot_image = config.chat.bot.default_name
) {
  const bot = chatObject(bot_name, bot_image);
  const ban = chatObject(`[InactiveAccount]`, ``, ``);
  const server = chatObject(bot_name, bot_image);

  if (chat?.type == "message") {
    if (chat?.id > 0) return chat?.[field];
    if (chat?.id == 0) return ban?.[field];
  } else if (chat?.type == "bot") {
    return bot?.[field];
  } else if (chat?.type == "server") {
    return server?.[field];
  } else {
    //must be a user from a poorly wired up component. fix and remove this in the future
    if (chat !== undefined) return chat?.[field];
    return ban[field];
  }
}
